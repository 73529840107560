<template>

    <div class="wrapper">

      <div class="card" :class="item.classCSSCorCard" v-for="item in avisos" v-bind:key="item.id">

          <span class="title" :class="item.classCSSCorTextos">{{item.titulo}}</span>

          <p :class="item.classCSSCorTextos" v-html="item.mensagem"></p>

          <div>
            <span class="data" :class="item.classCSSCorTextos">{{item.usuario}} • </span>
            <span class="data" :class="item.classCSSCorTextos">{{item.data}}</span>
          </div>
      </div>

    </div>

</template>



<script>
import api from "../services/api";
// import { msg } from "@/global";
export default {

  data() {
    return {

      avisos: [],   
  
    }
  },

  created() {

    this.getAvisos()
  },

  methods: {

    getAvisos() {
      
      this.avisos = JSON.parse(localStorage.getItem('avisos'))

      this.atualizaAvisosParaLidos()
    },

    
    atualizaAvisosParaLidos() {

      const avisos = this.avisos.filter(item => item.classCSSCorCard == 'card-nao-lido')
      
      api.put('avisos', { avisos: avisos })
      .then(() => {
        
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });

    }

  },

  beforeDestroy() {

    api.get(`avisos`)
    .then((res) => {
        
        this.avisos = res.data
        localStorage.setItem('avisos', JSON.stringify(res.data))

    })
    .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) }) 

  }

};
</script>



<style scoped>

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.wrapper{
  max-width: 1080px;
  margin: 50px auto;
  padding:  0 20px;
}

.card {
  margin-top: 15px;
  border: #a06dcf;
  border-style: solid;
  border-width: 4px;
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.card-nao-lido {
  background: #a06dcf;
}

.card-lido {
  background: #ffffff;
}

.title {
  margin-bottom: 2px;
  font-size: 30px;
  font-weight: 600;
}

.textosCor-Lido {
  color: #a06dcf;
}

.textosCor-naoLido {
  color: white;
}

.data{
  margin-top: 10px;
  font-size: 17px;
}


.card p{
  margin: 10px 0 17px 0;
  font-size: 20px;
  font-weight: 600;
}



@media(max-width: 772px){

  .wrapper .center-line{
    left: 40px;
  }
  .wrapper .row{
    margin: 30px 0 3px 60px;
  }
  .wrapper .row {
    width: 100%;
  }

}



</style>