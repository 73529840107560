// import api from '../services/api'

export default {

    namespaced: true,
    state: {
        items: []
    },

    mutations: {
        SET_ITEMS(state, items) {
            state.items = items
        }
    },

    actions: {


        getFaturamento(context) {
            const items = [
                { id: 1, text: 'Conecitos Vuex', done: true },
                { id: 2, text: 'Conecitos Vue', done: true },
                { id: 3, text: 'Conecitos Prisma', done: true },
                { id: 4, text: 'Conecitos Typescript', done: true },
            ]

            context.commit('SET_ITEMS', items)
        },

        saveFaturamento(context, dados) {
            context.state.items.push(dados)
        }
    },

    getters: {
        allFaturamentos(state) {
            return state.items
        }
    }

}