import Vue from 'vue'
import VueRouter from 'vue-router'

import Dashboard from '../pages/Dashboard'
import Home from '../pages/Home'
import GerenciarUsuarios from '../pages/admin/GerenciarUsuarios'
import Avisos from '../pages/Avisos'
import Login from '../pages/auth/Login'
import AlterarSenha from '../pages/auth/AlterarSenha'
import Faturamentos from '../pages/faturamento/Faturamentos'




Vue.use(VueRouter)


const routes = [
    { name: 'home', path: '/home', component: Home },
    { name: 'dashboard', path: '/dashboard', component: Dashboard },
    { name: 'avisos', path: '/avisos', component: Avisos },
    { name: 'gerenciarUsuarios', path: '/gerenciar-usuarios', component: GerenciarUsuarios },
    { name: 'auth', path: '/login', component: Login },
    { name: 'alterarSenha', path: '/alterar-senha', component: AlterarSenha },
    { name: 'faturamentos', path: '/faturamentos', component: Faturamentos },
    
]





const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})


export default router