<template>
    <div id="home">

        <!-- <h1>Oi</h1>
        <button @click="saveFaturamento">Salvar</button>
        <button @click="clearFaturamentos">Limpar</button>

        <ul>
            <li v-for="item in faturamentos" :key="item.id">
                {{item.text}}
            </li>
        </ul> -->


    </div>
</template>

<script>
export default {

    created() {

        this.clearFaturamentos()
        // this.$store.dispatch('faturamento/getFaturamento')
        // this.$store.commit("auth/toggleMenu", true);
    },

    computed: {
        faturamentos() {
            // return this.$store.state.faturamento.items
            // return this.$store.getters['faturamento/allFaturamentos'](myParam)
            return this.$store.getters['faturamento/allFaturamentos']
        }
    },

    methods: {

        saveFaturamento() {
            const dados = { id: 5, text: 'Conecitos NodeJS', done: true }
            this.$store.dispatch('faturamento/saveFaturamento', dados) 
        },

        clearFaturamentos() {
            this.$store.dispatch('faturamento/getFaturamento') 
        }

    }

}
</script>

<style scoped>
    
</style>





