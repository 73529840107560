import Vue from 'vue'
import Vuex from 'vuex'

import escala from './escala'
import auth from './auth'
import faturamento from './faturamento'
import api from '../services/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    devtools: false,
    namespaced: true,
    state: {
        empresa: null,
        empresas: []
    },
    mutations: {

        setEmpresas(state, empresas) {
            // console.log('ststs', empresas)
            state.empresas = empresas
        },

        setEmpresa(state, empresa) {
            // console.log('setempresa', empresa)
            state.empresa = empresa
            api.defaults.headers.common['empresa'] = JSON.stringify(empresa)
        },
        
    },

    actions: {
        getAuth(state) {
            return state.isAuth
        }
    },
    modules: {
        auth: auth,
        faturamento: faturamento,
        escala: escala,
    }
})

export default store