<template>
<div class="page">

  <b-row class="mt-2">
    <b-col sm="11" >
      <h2>Faturamento</h2>
    </b-col>
    <b-col sm="1" >
      <!-- <b-button class="btn-right" variant="btn btn-outline-info btn-sm" link to="/"> Fechar</b-button> -->
    </b-col>
  </b-row>  


  <hr class="mb-4 mt-0">

  <!-- FILTROS DA TELA --> 
  <div class="row text-left col-lg-12">

    <div class="form-group col-lg-3">
      <label>Estabelecimentos</label>
      <b-form-select
        v-model="filtro.estabelecimento" 
        style="height: 35px; margin-top: -2px"
        :options="estabelecimentos" 
      ></b-form-select>
    </div>

    <div class="form-group col-lg-2">
      <b-form-group label="Período">
        <date-picker  
            id="input-periodo"
            v-model="filtro.periodo"
            lang="pt-br" 
            range
            style="width: 100%;"
            type="date"
            format="DD/MM/YYYY"
            @input="getFaturamentos"
        >       
        </date-picker>
      </b-form-group>
    </div> 

    <div class="form-group col-lg-3">
      <label>Cliente</label>
      <b-form-select
        v-model="filtro.cliente" 
        style="height: 35px; margin-top: -2px"
        :options="clientes" 
      ></b-form-select>
    </div>
      
    <div class="form-group col-lg-4">
      <b-form-group label="Liberação">
        <b-form-radio-group  
          class="filtro-item" 
          v-model="filtro.status"
          :options="status"
          @change="selecionaStatus"
        ></b-form-radio-group>
      </b-form-group> 

    </div> 

  </div>
  <!-- FIM - FILTROS DA TELA -->



    <!-- <b-card class="mt-3" header="Resultado do Form">
        <pre class="m-0">status.selected: {{ filtro.status }}</pre>
    </b-card> -->




  <div class="div-corpo">


    <b-jumbotron bg-variant="light">




      <!-- <b-table :key="componentKey"
        :items="faturamentos"
        :fields="fieldsFaturamentosGerarNF"
        striped
        hover
      >

      <template v-slot:head(selecionado)> 
        <b-form-checkbox
          v-model="selecioneTudo"
          name="checkbox-2"
          value="true"
          unchecked-value="false"
          @change="selecioneFaturamentos"
        >
        </b-form-checkbox> 
      </template>


      <template #cell(selecionado)="data">
        <b-form-checkbox
          v-model="data.item.selecionado"
          name="checkbox-1"
          value="true"
          unchecked-value="false"
        >
        </b-form-checkbox>            
      </template> 

      </b-table> -->


      
      <div class="tabela">

        <b-table :key="componentKey"  
          class="tabela"
          :items="faturamentos"  
          :fields="fieldsFaturamentos"   
          :busy="load" 
          ref="selectableTable"
          responsive="sm"   
          :select-mode="multi"
          striped
          hover 
          small
          outlined
        > 

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>



          <template v-slot:head(selecionado) v-if="filtro.status == 'Gerar NF'"> 
            <!-- <span v-html="data.field.key"></span> -->
            <b-form-checkbox
              v-model="selecioneTudo"
              name="checkbox-2"
              value="true"
              unchecked-value="false"
              @change="selecioneFaturamentos"
            >
            </b-form-checkbox> 
          </template>


          <template #cell(selecionado)="data" v-if="filtro.status == 'Gerar NF'">
            <b-form-checkbox
              v-model="data.item.selecionado"
              name="checkbox-1"
              value="true"
              unchecked-value="false"
            >
            </b-form-checkbox>            
          </template> 


          <template #cell(notaFiscal)="data">
            {{data.item.notaFiscal}}
            <b-icon class="cursor" icon="file-text-fill" variant="primary"  v-if="data.item.notaFiscal" 
              style="margin-top: 8px; margin-left: 5px; width: 21px; height: 21px;"
              @click="openNotaFiscal(data.item)"
            >
            </b-icon>
          </template> 

          <template #cell(numeroFaturamento)="data">
            {{data.item.numeroFaturamento}}
            <b-icon class="cursor" icon="file-text-fill" variant="primary"  v-if="data.item.numeroFaturamento" 
              style="margin-top: 8px; margin-left: 5px; width: 21px; height: 21px;"
              @click="openFaturamento(data.item)"
            >
            </b-icon>
          </template> 


          <template #cell(numeroND)="data">
            {{data.item.numeroND}}
            <b-icon class="cursor" icon="file-text-fill" variant="primary"  v-if="data.item.numeroND" 
              style="margin-top: 8px; margin-left: 5px; width: 21px; height: 21px;"
              @click="openND(data.item)"  
            >
            </b-icon>
          </template> 


        </b-table>
      </div> 



    </b-jumbotron>
  </div>


  <b-row align-h="end" v-if="filtro.status == 'Gerar NF'">
    <b-button class="mt-3 botao-confirmar2" variant="info" block>Consultar Retorno NF</b-button>
    <b-button class="mt-3 botao-confirmar2" variant="success" block>Transmitir NF</b-button>
  </b-row>

  <b-row align-h="end" v-if="filtro.status == 'Documentos Emitidos'">
    <b-button class="mt-3 botao-confirmar2" variant="danger" block>Cancelar Notas</b-button>
  </b-row>

  

</div>
</template>

<script>

import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import api from '../../services/api'

// import Vue from 'vue';

export default {
  name: "Faturamentos",
  components: { DatePicker },

  data() {
    return {

      componentKey: 0,

      load: false,
      stickyHeader: true,
      noCollapse: false,

      selecioneTudo: false,

      clientes: [],

      filtro: {
        estabelecimento: '',
        cliente: 'TODOS',
        status: 'Todos',
        periodo:[]
      },

      // status: ['Todos','Gerar NF','Enviar Documentos', 'Documentos Enviados'],
      // estabelecimentos: ['São PAULO','BAHIA','MINAS GERAIS'],
      estabelecimentos: [],
      status: ['Todos','Gerar NF','Documentos Emitidos'],


      allFaturamentos: [],

      fieldsFaturamentos: [],

      fieldsFaturamentosTodos: [
        { key: 'processo', label: 'Processo' },
        { key: 'contrato', label: 'Cliente' , sortable: true},
        { key: 'dataDocumento', label: 'Período' , sortable: true},
        { key: 'valor', label: 'Valor' , sortable: true, thClass: 'text-right', tdClass: 'text-right', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        { key: 'notaFiscal', label: 'Nota Fiscal', sortable: true, thClass: 'text-right', tdClass: 'text-right' },
        { key: 'numeroFaturamento', label: 'Fatura', sortable: true, thClass: 'text-right', tdClass: 'text-right' },
        { key: 'numeroND', label: 'ND', sortable: true },  
        { key: 'envioEmail', label: 'Envio E-mail', sortable: true },  
        { key: 'tipoPagamento', label: 'Tipo Recebimento', sortable: true },  
        { key: 'vencimento', label: 'Vencimento', sortable: true },  
        { key: 'status', label: 'Status', sortable: true },  
      ],

      fieldsFaturamentosGerarNF: [
        { key: 'selecionado', label: ''},
        { key: 'processo', label: 'Processo' },
        { key: 'contrato', label: 'Cliente' , sortable: true},
        { key: 'dataDocumento', label: 'Período' , sortable: true},
        { key: 'valor', label: 'Valor' , sortable: true, thClass: 'text-right', tdClass: 'text-right', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        { key: 'notaFiscal', label: 'Nota Fiscal', sortable: true, thClass: 'text-right', tdClass: 'text-right' },
        { key: 'numeroFaturamento', label: 'Fatura', sortable: true, thClass: 'text-right', tdClass: 'text-right' },
        { key: 'numeroND', label: 'ND', sortable: true },  
        { key: 'envioEmail', label: 'Envio E-mail', sortable: true },  
        { key: 'tipoPagamento', label: 'Tipo Recebimento', sortable: true },  
        { key: 'vencimento', label: 'Vencimento', sortable: true },  
        { key: 'status', label: 'Status Transm', sortable: true },  
      ],


    }
  },

  created() {

    let dataInicio = dayjs().startOf('month')
    dataInicio = new Date(dataInicio)
    
    this.filtro.periodo[0] = dataInicio
    this.filtro.periodo[1] = new Date()
    
    this.fieldsFaturamentos = this.fieldsFaturamentosTodos
    
    // this.filtro.cliente = 'TODOS'
    this.getFaturamentos()
  },

  computed: {

      faturamentos() {


        var resultado = []
        if (this.allFaturamentos.length == 0) 
        return resultado


        if (this.filtro.cliente == 'TODOS') {
          resultado = this.allFaturamentos
        }
        else {
          resultado = this.allFaturamentos.filter(item => item.contrato == this.filtro.cliente)
        }


        if (this.filtro.status == 'Todos') {
          return resultado
        }
        else
        if (this.filtro.status == 'Gerar NF') {
          resultado = resultado.filter(item => !item.notaFiscal)
        }
        else
        if (this.filtro.status == 'Documentos Enviados') {
          resultado = resultado.filter(item => item.notaFiscal)
        }
          

        return resultado
      }
      
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    forceRerender() {
      this.componentKey += 1;  
    },


    selecionaStatus() {
      // alert(this.filtro.status)
      // this.fieldsFaturamentos = this.fieldsFaturamentosGerarNF 


      this.fieldsFaturamentos = this.filtro.status == 'Gerar NF' 
      ? this.fieldsFaturamentosGerarNF 
      : this.fieldsFaturamentosTodos
    },


    async selecioneFaturamentos() {

      this.allFaturamentos.forEach(item => {
        item.selecionado = this.selecioneTudo
      })
      // console.log(this.allFaturamentos)
      // await new Promise(r => setTimeout(r, 500));
      this.forceRerender()

    },



    async getFaturamentos() {

      this.load = true

      api.get(`faturamentos?periodo=${this.filtro.periodo}`)
      .then((res) => {

        this.estabelecimentos = res.data.estabelecimentos
        if (this.estabelecimentos.length == 1)
        this.filtro.estabelecimento = this.estabelecimentos[0]


        this.allFaturamentos = res.data.faturamentos
        this.allFaturamentos.forEach(item => {
          item.selecionado = false
        })
        
        this.load = false

        // Alimenta os clientes para o combobox
        const clientes = res.data.faturamentos.map(item => item.contrato)
        this.clientes = [...new Set(clientes)]
        this.clientes = ['TODOS', ...this.clientes]
        
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
        this.load = false
      })
      
    },
    
    openNotaFiscal(faturamento) {
      // console.log(faturamento)
      if (!faturamento.codLink || faturamento.codLink.length == 0) {
        this.msgNotification('Nota fiscal está sem link.', 'info', 4000)
        return
      }
      
      window.open(faturamento.codLink, "_blank");
    },

    openFaturamento(faturamento){
      // console.log(faturamento)
      window.open(faturamento.codLinkFaturamento, "_blank");
    },

    openND(faturamento){
      // console.log(faturamento)
      window.open(faturamento.codLinkND, "_blank");
    },

  },

}
</script>

<style scoped src="./Faturamentos.css"></style>